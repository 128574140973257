import React, {useState, useEffect} from 'react';
import {Container, Typography, TextField, Button, Box, Card, CardContent, List, ListItem, ListItemText} from '@mui/material';
import {AccountCircle} from '@mui/icons-material';
import {useLoginStatus} from "./LoginStatusContext ";

const Login = () => {
    const {isLoggedIn, login, logout} = useLoginStatus();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [cinemaDates, setCinemaDates] = useState({});
    const [closedTheaters, setClosedTheaters] = useState({});

    const handleLogin = async () => {
        login(username, password)
    };
    const handleLogout = async () => {
        logout()
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetch('/api/all_latest_dates')
                .then((res) => res.json())
                .then((data) => {
                    setCinemaDates(data);
                })
                .catch((err) => {
                    console.error("Error fetching all latest dates:", err);
                });
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isLoggedIn) {
            fetch('/api/closed_theaters')
                .then((res) => res.json())
                .then((data) => {
                    setClosedTheaters(data);
                })
                .catch((err) => {
                    console.error("Error fetching closed theaters:", err);
                });
        }
    }, [isLoggedIn]);

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);

    const filteredTheaters = Object.keys(cinemaDates).filter(theater => {
        const latest = cinemaDates[theater];
        if (!latest) return false;
        const latestDate = new Date(latest);
        latestDate.setHours(0, 0, 0, 0);
        if (latestDate.getTime() > tomorrow.getTime()) {
            return false;
        }
        if (closedTheaters[theater]) {
            const closedDate = new Date(closedTheaters[theater]);
            closedDate.setHours(0, 0, 0, 0);
            if (today.getTime() > closedDate.getTime()) {
                return false;
            }
        }
        return true;
    });

    return (
        <Container maxWidth="xs">
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mt={5}
            >
                <AccountCircle fontSize="large"/>

                {isLoggedIn ? (<>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogout}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Logout
                    </Button>
                    <Card sx={{ width: '100%', mt: 3, boxShadow: 3 }}>
                        <CardContent>
                            <Typography variant="h6" gutterBottom>
                                各戲院最新電影日期 (僅顯示明天及以前且未退場)
                            </Typography>
                            {filteredTheaters.length === 0 ? (
                                <Typography variant="body1" align="center">
                                    無符合條件之戲院
                                </Typography>
                            ) : (
                                <List>
                                    {filteredTheaters.map((theater) => {
                                        const latest = cinemaDates[theater];
                                        const latestDate = new Date(latest);
                                        latestDate.setHours(0, 0, 0, 0);
                                        let displayColor = "inherit";
                                        if (latestDate.getTime() <= today.getTime()) {
                                            displayColor = "red";
                                        }
                                        return (
                                            <ListItem key={theater} disableGutters>
                                                <ListItemText
                                                    primary={`${theater}: ${latestDate.toLocaleDateString('zh-TW')}`}
                                                    primaryTypographyProps={{
                                                        style: { color: displayColor, fontWeight: 500 }
                                                    }}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            )}
                        </CardContent>
                    </Card>
                </>) : (<>
                    <Typography variant="h5" component="h2" mt={2}>
                        Login
                    </Typography>
                    <TextField
                        label="Username"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        label="Password"
                        type="password"
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Login
                    </Button>


                </>)}

            </Box>
        </Container>
    );
};

export default Login;