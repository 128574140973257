import React, { useState, useRef } from 'react';
import { 
  Button, 
  FormControl, 
  InputLabel, 
  MenuItem, 
  Select, 
  Box, 
  Typography,
  Paper,
  Container,
  TextField,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Snackbar,
  Alert,
  Modal,
  IconButton,
} from '@mui/material';
import { Launch, ZoomIn, ZoomOut } from '@mui/icons-material';
import { Link as MuiLink } from '@mui/material';

function ImageUploadForm() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedTheater, setSelectedTheater] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [latestDate, setLatestDate] = useState(null);
  const [latestDates, setLatestDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [aiPrompt, setAiPrompt] = useState('');
  const [theaters] = useState([
    { theater_group: '麻豆戲院' },
    { theater_group: '員林影城' }
  ]);
  const [recognitionResults, setRecognitionResults] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });
  const [previewUrl, setPreviewUrl] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const animationRef = useRef(null);

  const theaterLinks = {
    '麻豆戲院': 'https://www.facebook.com/profile.php?id=61564946135169&locale=zh_TW',
    '員林影城': 'https://www.facebook.com/YuanlinCinema'
  };

  const handleImageChange = (event, file) => {
    const selectedFile = file || event.target.files[0];
    setSelectedImage(selectedFile);

    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setPreviewUrl(url);
    } else {
      setPreviewUrl(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files[0];
    handleImageChange(event, file);
  };

  const handleTheaterChange = async (event) => {
    const theater = event.target.value;
    setSelectedTheater(theater);
    
    try {
      const response = await fetch(`/api/real_movies/latest_date?theater=${encodeURIComponent(theater)}`);
      if (response.ok) {
        const data = await response.json();
        setLatestDate(data.latest_date);
      }
    } catch (error) {
      console.error('Error fetching latest date:', error);
      setLatestDate(null);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleSubmit = async () => {
    if (!selectedImage || !selectedTheater) {
      setSnackbar({
        open: true,
        message: '請選擇圖片和戲院',
        severity: 'error'
      });
      return;
    }

    if (selectedTheater === '員林影城' && (!startDate || !endDate)) {
      setSnackbar({
        open: true,
        message: '請選擇日期區間',
        severity: 'error'
      });
      return;
    }

    setLoading(true);

    const reader = new FileReader();
    reader.readAsDataURL(selectedImage);
    reader.onload = async () => {
      const base64Image = reader.result.split(',')[1];

      try {
        const response = await fetch('/api/upload-image', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            image: base64Image,
            theater: selectedTheater,
            startDate: startDate,
            endDate: endDate,
            aiPrompt: aiPrompt,
          }),
        });

        if (response.ok) {
          const result = await response.json();
          setSnackbar({
            open: true,
            message: '圖片上傳成功',
            severity: 'success'
          });
          console.log('AI 辨識結果:', result);
          setRecognitionResults(result);
        } else {
          const errorData = await response.json();
          setSnackbar({
            open: true,
            message: `圖片上傳失敗: ${errorData.error || '未知錯誤'}`,
            severity: 'error'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        setSnackbar({
          open: true,
          message: '圖片上傳失敗',
          severity: 'error'
        });
      } finally {
        setLoading(false);
      }
    };
  };

  const handlePreviewOpen = () => {
    setOpenPreview(true);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
    setZoomLevel(1);
    setDragOffset({ x: 0, y: 0 });
  };

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.2, 3));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.2, 0.5));
  };

  const handleWheel = (event) => {
    event.preventDefault();
    if (event.deltaY < 0) {
      // 向上滾動 - 放大
      setZoomLevel(prev => Math.min(prev + 0.1, 3));
    } else {
      // 向下滾動 - 縮小
      setZoomLevel(prev => Math.max(prev - 0.1, 0.5));
    }
  };

  const handleMouseDown = (event) => {
    event.preventDefault();
    setDragStart({
      x: event.clientX - dragOffset.x,
      y: event.clientY - dragOffset.y,
    });
    setIsDragging(true);
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    if (!isDragging) return;

    const x = event.clientX - dragStart.x;
    const y = event.clientY - dragStart.y;

    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }

    animationRef.current = requestAnimationFrame(() => {
      setDragOffset({ x, y });
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragStart({ x: 0, y: 0 });
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  React.useEffect(() => {
    return () => {
      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [previewUrl]);

  React.useEffect(() => {
    if (!selectedTheater) {
      theaters.forEach(async (theater) => {
        try {
          const response = await fetch(`/api/real_movies/latest_date?theater=${encodeURIComponent(theater.theater_group)}`);
          if (response.ok) {
            const data = await response.json();
            setLatestDates(prev => ({
              ...prev,
              [theater.theater_group]: data.latest_date
            }));
          }
        } catch (error) {
          console.error(`Error fetching latest date for ${theater.theater_group}:`, error);
        }
      });
    }
  }, [selectedTheater, theaters]);

  return (
    <Container maxWidth="lg">
      <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" gutterBottom>
          圖片辨識上傳
        </Typography>
        
        <Box sx={{ display: 'flex', gap: 4, position: 'relative' }}>
          <Box
            component="form"
            sx={{
              flex: 1,
              '& > :not(style)': { width: '100%', mb: 2 },
              position: 'relative',
            }}
          >
            {loading && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  zIndex: 1,
                }}
              >
                <CircularProgress />
              </Box>
            )}

            <FormControl fullWidth>
              <InputLabel id="theater-select-label">戲院</InputLabel>
              <Select
                labelId="theater-select-label"
                id="theater-select"
                value={selectedTheater}
                label="戲院"
                onChange={handleTheaterChange}
              >
                {theaters.map((theater) => (
                  <MenuItem key={theater.theater_group} value={theater.theater_group}>
                    {theater.theater_group}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedTheater ? (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <MuiLink
                  href={theaterLinks[selectedTheater]}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ textDecoration: 'none' }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<Launch />}
                    size="small"
                  >
                    前往{selectedTheater} Facebook
                  </Button>
                </MuiLink>
                {latestDate && (
                  <Typography variant="body2" color="text.secondary">
                    最新電影日期: {new Date(latestDate).toLocaleDateString('zh-TW')}
                  </Typography>
                )}
              </Box>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Typography variant="body2" color="text.secondary">
                  {"麻豆戲院 最新電影日期: " +
                    (latestDates['麻豆戲院']
                      ? new Date(latestDates['麻豆戲院']).toLocaleDateString('zh-TW')
                      : '載入中...')}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {"員林影城 最新電影日期: " +
                    (latestDates['員林影城']
                      ? new Date(latestDates['員林影城']).toLocaleDateString('zh-TW')
                      : '載入中...')}
                </Typography>
              </Box>
            )}

            <Box>
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleImageChange}
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  選擇圖片
                </Button>
              </label>
              {selectedImage && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  已選擇: {selectedImage.name}
                </Typography>
              )}
            </Box>

            {selectedTheater === '員林影城' && (
              <Box>
                <TextField
                  type="date"
                  label="開始日期"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  type="date"
                  label="結束日期"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </Box>
            )}

            <TextField
              multiline
              rows={4}
              label="自定義 AI Prompt"
              value={aiPrompt}
              onChange={(e) => setAiPrompt(e.target.value)}
              placeholder="在此輸入自定義的 AI Prompt..."
              fullWidth
              sx={{ mb: 2 }}
            />

            <Button 
              variant="contained" 
              onClick={handleSubmit}
              disabled={!selectedImage || !selectedTheater || loading}
            >
              上傳並辨識
            </Button>
          </Box>

          <Box
            sx={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              minHeight: 300,
              bgcolor: 'grey.100',
              borderRadius: 1,
              p: 2,
              cursor: 'pointer',
              border: '2px dashed',
              borderColor: isDragging ? 'primary.main' : 'grey.300',
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            {previewUrl ? (
              <>
                <img
                  src={previewUrl}
                  alt="預覽"
                  style={{
                    maxWidth: '100%',
                    maxHeight: '500px',
                    objectFit: 'contain',
                    cursor: 'pointer'
                  }}
                  onClick={handlePreviewOpen}
                />
                <Modal
                  open={openPreview}
                  onClose={handlePreviewClose}
                  aria-labelledby="preview-modal"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    '& .MuiBackdrop-root': {
                      backgroundColor: 'rgba(0, 0, 0, 0.8)',
                    }
                  }}
                >
                  <Box sx={{
                    position: 'relative',
                    maxWidth: '90vw',
                    maxHeight: '90vh',
                    outline: 'none',
                  }}>
                    <Box sx={{
                      position: 'absolute',
                      top: -48,
                      right: 0,
                      display: 'flex',
                      gap: 1
                    }}>
                      <IconButton
                        onClick={handleZoomOut}
                        sx={{ color: 'white' }}
                      >
                        <ZoomOut />
                      </IconButton>
                      <IconButton
                        onClick={handleZoomIn}
                        sx={{ color: 'white' }}
                      >
                        <ZoomIn />
                      </IconButton>
                    </Box>
                    <Box sx={{
                      width: '90vw',
                      height: '90vh',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      overflow: 'auto',
                    }}>
                      <img
                        src={previewUrl}
                        alt="預覽"
                        onWheel={handleWheel}
                        onMouseDown={handleMouseDown}
                        onMouseMove={handleMouseMove}
                        onMouseUp={handleMouseUp}
                        style={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          transform: `scale(${zoomLevel}) translate(${dragOffset.x}px, ${dragOffset.y}px)`,
                          transition: isDragging ? 'none' : 'transform 0.1s ease-in-out',
                          transformOrigin: 'center center',
                          cursor: 'move',
                          willChange: 'transform',
                        }}
                      />
                    </Box>
                  </Box>
                </Modal>
              </>
            ) : (
              <Typography variant="body1" color="text.secondary">
                選擇圖片或拖曳圖片到此處
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
      {recognitionResults && recognitionResults.length > 0 && (
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            辨識結果
          </Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>電影名稱</TableCell>
                  <TableCell>日期</TableCell>
                  <TableCell>時間</TableCell>
                  <TableCell>類型</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recognitionResults && recognitionResults.reduce((acc, movie) => {
                  const existingMovieIndex = acc.findIndex(
                    (item) =>
                      item.name === movie.name &&
                      item.movie_date === movie.movie_date &&
                      item.movie_type === movie.movie_type
                  );
                  
                  if (existingMovieIndex > -1) {
                    acc[existingMovieIndex].movie_time += `, ${movie.movie_time}`;
                  } else {
                    acc.push({ ...movie });
                  }
                  
                  return acc;
                }, []).map((movie, index) => (
                  <TableRow key={index}>
                    <TableCell>{movie.name}</TableCell>
                    <TableCell>{new Date(movie.movie_date).toLocaleDateString('zh-TW')}</TableCell>
                    <TableCell>{movie.movie_time}</TableCell>
                    <TableCell>{movie.movie_type}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {recognitionResults && recognitionResults.length === 0 && (
        <Paper elevation={3} sx={{ p: 3, mt: 2 }}>
          <Typography variant="body1">
            未辨識到電影資訊。
          </Typography>
        </Paper>
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default ImageUploadForm;
